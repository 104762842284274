import { Injectable } from '@angular/core';
import { IndexedDbItem } from '@models/indexed-db-item';
import { catchError, from, map, Observable, switchMap, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class IndexedDbService {
  private connections: Map<string, IDBDatabase> = new Map();

  constructor() {}

  /**
   * Creates a new database with a store.
   *
   * @param {string} dbName name of the database
   *
   * @returns {Observable<boolean>} observable indicating whether the database was created successfully
   */
  public createDatabase(dbName: string): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      const request = indexedDB.open(dbName, 1);

      request.onupgradeneeded = (event: IDBVersionChangeEvent) => {
        const db = (event.target as IDBOpenDBRequest).result;
        const storeName = this.getStoreName(dbName);

        if (!db.objectStoreNames.contains(storeName)) {
          db.createObjectStore(storeName, { keyPath: 'key' });
        }
      };

      request.onsuccess = (event: Event) => {
        this.connections.set(dbName, (event.target as IDBOpenDBRequest).result);
        observer.next(true);
        observer.complete();
      };

      request.onerror = (event: Event) =>
        observer.error(`Error creating database: ${(event.target as IDBOpenDBRequest).error}`);
    });
  }

  /**
   * Saves an item to the store.
   *
   * @param {string} dbName name of the database
   * @param {T} item item to be saved
   * @param {string} key key for the item
   *
   * @returns {Observable<IDBValidKey>} observable with the key of the saved item
   */
  public saveItem<T>(dbName: string, item: T, key: string): Observable<IDBValidKey> {
    return this.executeTransaction<IDBValidKey>(dbName, 'readwrite', (store) =>
      from(this.promisifyRequest<IDBValidKey>(store.put({ key, data: item })))
    );
  }

  /**
   * Retrieves all items from the store.
   *
   * @param {string} dbName name of the database
   *
   * @returns {Observable<IndexedDbItem<T>[]>} observable with an array of all stored items
   */
  public getAllItems<T>(dbName: string): Observable<IndexedDbItem<T>[]> {
    return this.executeTransaction<IndexedDbItem<T>[]>(dbName, 'readonly', (store) =>
      from(this.promisifyRequest<IndexedDbItem<T>[]>(store.getAll()))
    );
  }

  /**
   * Deletes an item from the store.
   *
   * @param {string} dbName name of the database
   * @param {IDBValidKey} key key of the item to delete
   *
   * @returns {Observable<void>} observable indicating successful deletion
   */
  public deleteItem(dbName: string, key: IDBValidKey): Observable<void> {
    return this.executeTransaction<void>(dbName, 'readwrite', (store) =>
      from(this.promisifyRequest<undefined>(store.delete(key))).pipe(map(() => undefined))
    );
  }

  /**
   * Deletes an entire database.
   *
   * @param {string} dbName name of the database to delete
   *
   * @returns {Observable<void>} observable indicating successful deletion
   */
  public deleteDatabase(dbName: string): Observable<void> {
    if (this.connections.has(dbName)) {
      this.connections.get(dbName)?.close();
      this.connections.delete(dbName);
    }

    return new Observable<void>((observer) => {
      const request = indexedDB.deleteDatabase(dbName);

      request.onsuccess = () => {
        observer.next();
        observer.complete();
      };

      request.onerror = (event) => observer.error(`Error deleting database: ${(event.target as IDBRequest).error}`);
    });
  }

  /**
   * Gets the names of all existing IndexedDB databases.
   *
   * @returns {Observable<string[]>} observable with array of database names
   */
  public getAllDatabaseNames(): Observable<string[]> {
    return from(indexedDB.databases()).pipe(map((databases) => databases.map((db) => db.name).filter(Boolean)));
  }

  /**
   * Executes a transaction on the specified object store.
   *
   * If the database doesn't exist, it attempts to create it first.
   *
   * @param {string} dbName name of the database
   * @param {IDBTransactionMode} mode transaction mode (readonly or readwrite)
   * @param {(store: IDBObjectStore) => Observable<T>} callback function to execute the transaction
   *
   * @returns {Observable<T>} observable with the transaction result
   */
  private executeTransaction<T>(
    dbName: string,
    mode: IDBTransactionMode,
    callback: (store: IDBObjectStore) => Observable<T>
  ): Observable<T> {
    const db = this.connections.get(dbName);

    if (!db) {
      return this.createDatabase(dbName).pipe(switchMap(() => this.executeTransaction(dbName, mode, callback)));
    }

    try {
      const storeName = this.getStoreName(dbName);
      const transaction = db.transaction(storeName, mode);
      const store = transaction.objectStore(storeName);

      return callback(store).pipe(catchError((err) => throwError(() => new Error(`Transaction error: ${err}`))));
    } catch (error) {
      return throwError(() => new Error(`Error creating transaction: ${error}`));
    }
  }

  /**
   * Wraps an IndexedDB request in a Promise.
   *
   * @param {IDBRequest<T>} request indexeddb request to wrap
   *
   * @returns {Promise<T>} promise resolving with the request result
   */
  private promisifyRequest<T>(request: IDBRequest<T>): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      request.onsuccess = () => resolve(request.result);
      request.onerror = () => reject(request.error);
    });
  }

  /**
   * Gets the standardized store name for a given database.
   *
   * @param {string} dbName name of the database
   *
   * @returns {string} the standardized store name
   */
  private getStoreName(dbName: string): string {
    return `${dbName}-store`;
  }
}
