// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input.ng-invalid.ng-touched, input.expired-card,
textarea.ng-invalid.ng-touched,
textarea.expired-card,
select.ng-invalid.ng-touched,
select.expired-card {
  background-color: #fceeee;
  outline: 1px solid #de4444;
}

.error {
  color: #de4444;
  font-weight: bold;
  font-size: 0.75rem;
  margin-top: 0.5em;
}
.error a {
  color: #de4444;
  text-decoration: underline;
}

.continue-button {
  min-width: 200px;
  margin: 0 auto;
  display: block;
}

.consultation-request-container {
  padding: 0 0.625rem;
}
@media screen and (min-width: 769px) {
  .consultation-request-container {
    padding: 0 0.9375rem;
  }
}

.has-text-weight-semibold h1 {
  font-weight: 600;
}

.questionnaire-section-title {
  font-size: 18px;
  font-weight: 700;
  color: #363636;
  margin-top: 24px;
  margin-bottom: 16px;
}

app-medical-history-questions .label {
  font-weight: 700 !important;
}

.std-prevention-terms-container label {
  font-weight: normal !important;
  color: black !important;
}
.std-prevention-terms-container app-checkbox-input label {
  font-weight: 700 !important;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
