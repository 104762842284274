// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.only-healthlabs {
  display: none !important;
}

.only-stdcheck {
  display: none !important;
}

body {
  color: #2f2f2f;
  font-family: Poppins, serif;
}
body .main-content {
  padding: 1rem;
  background-color: #fefcfa;
}

app-starfish-layout input,
app-starfish-layout textarea,
app-starfish-layout select {
  font-family: Poppins, serif !important;
}
app-starfish-layout .label {
  font-weight: 700 !important;
}
app-starfish-layout .starfish-header {
  background-color: #fff4e9;
  width: 100%;
  padding: 16px 0;
}
@media screen and (min-width: 568px) {
  app-starfish-layout .starfish-header {
    padding: 23.9px 0;
  }
}
@media screen and (min-width: 1024px) {
  app-starfish-layout .starfish-header {
    padding: 23.65px 0;
  }
}
@media screen and (min-width: 1081px) {
  app-starfish-layout .starfish-header {
    padding: 23.65px 0;
  }
}
@media screen and (min-width: 1200px) {
  app-starfish-layout .starfish-header {
    padding: 20px 0;
  }
}
@media screen and (min-width: 1720px) {
  app-starfish-layout .starfish-header {
    padding: 28.75px 0;
  }
}
app-starfish-layout .starfish-header .container {
  padding: 0 20px;
}
app-starfish-layout .starfish-header .header-img {
  width: 199.71px;
}
@media screen and (min-width: 568px) {
  app-starfish-layout .starfish-header .header-img {
    width: 216px;
  }
}
@media screen and (min-width: 1024px) {
  app-starfish-layout .starfish-header .header-img {
    width: 229.5px;
  }
}
@media screen and (min-width: 1200px) {
  app-starfish-layout .starfish-header .header-img {
    width: 236.51px;
  }
}
@media screen and (min-width: 1720px) {
  app-starfish-layout .starfish-header .header-img {
    width: 270px;
  }
}
app-starfish-layout .starfish-header .phone-container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  -moz-column-gap: 8.5px;
       column-gap: 8.5px;
  color: inherit;
  text-decoration: none;
}
@media screen and (max-width: 1080px) {
  app-starfish-layout .starfish-header .phone-container {
    display: none;
    height: 51.37px;
  }
}
@media screen and (min-width: 1200px) {
  app-starfish-layout .starfish-header .phone-container {
    -moz-column-gap: 10px;
         column-gap: 10px;
  }
}
app-starfish-layout .starfish-header .phone-container .phone-icon {
  position: relative;
  display: inline-block;
  flex-shrink: 0;
  width: 26px;
  height: 26px;
  background: url('phone-sprite.9f2580370473348f.png') -10px -10px;
  transition: 0s;
}
app-starfish-layout .starfish-header .phone-container .phone-icon.animated {
  animation: callAnim 2s steps(1) infinite;
}
app-starfish-layout .starfish-header .phone-container .phone-number {
  font-weight: 600;
  font-size: 17px;
}
@media screen and (min-width: 1200px) {
  app-starfish-layout .starfish-header .phone-container .phone-number {
    font-size: 20px;
  }
}
app-starfish-layout .starfish-footer {
  background-image: url('starfish-footer-bg.a88045c688809148.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  color: white;
  padding: 25px 0;
}
@media screen and (max-width: 768px) {
  app-starfish-layout .starfish-footer .logo-column {
    padding-bottom: 0;
  }
}
app-starfish-layout .starfish-footer .footer-img {
  width: 270px;
}
app-starfish-layout .starfish-footer > .is-flex {
  gap: 16px;
}
app-starfish-layout .starfish-footer .container {
  padding: 0 20px;
}
app-starfish-layout .card {
  border: solid 1px #fefcfa;
}
app-starfish-layout .card .card-header {
  background: #fa676b;
  color: white;
  font-size: 1.75rem;
}
app-starfish-layout .container {
  max-width: 1210px !important;
}
app-starfish-layout .button.button.is-primary {
  background-color: #fa676b;
  font-weight: 700;
  font-family: Poppins, serif;
}
app-starfish-layout .button.button.is-primary:hover {
  background-color: #f84f55;
}
@keyframes callAnim {
  0% {
    background-position: -10px -10px;
  }
  33% {
    background-position: -56px -10px;
  }
  66% {
    background-position: -10px -56px;
  }
  100% {
    background-position: -10px -10px;
  }
}
app-starfish-layout app-order-summary .card .card-header {
  font-size: 1rem;
}
app-starfish-layout app-order-summary .card .card-header .card-header-icon:hover {
  text-decoration: underline;
}
app-starfish-layout app-order-summary .coupon .has-text-right {
  color: red;
}
app-starfish-layout app-order-summary .subtotal-row td:first-child {
  color: #fa676b;
  font-weight: bold;
}
app-starfish-layout app-icon-and-button-card .container {
  max-width: 50rem !important;
}
app-starfish-layout app-icon-and-button-card .card {
  padding: 2rem 1rem;
  border: none;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02);
}
app-starfish-layout app-icon-and-button-card .card .card-inner-container {
  display: block;
  margin: 0 auto;
  max-width: 37rem;
}
app-starfish-layout app-icon-and-button-card .card .card-inner-container .card-image {
  height: 100px;
  width: 100px;
  margin-bottom: 2rem;
  filter: brightness(0) saturate(100%) invert(48%) sepia(69%) saturate(962%) hue-rotate(318deg) brightness(99%) contrast(97%);
}
app-starfish-layout app-icon-and-button-card .card .card-inner-container .card-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #fa676b;
  text-align: center;
}
app-starfish-layout app-icon-and-button-card .card .card-inner-container .card-button {
  min-width: 200px;
}
app-starfish-layout app-order-service-agreement a {
  color: #fa676b;
  text-decoration: none;
}
app-starfish-layout app-order-service-agreement a:hover {
  color: #f84f55;
  text-decoration: underline;
}
app-starfish-layout app-place-order .button-place-your-order,
app-starfish-layout .default-button {
  background-color: #fa676b !important;
  font-family: Poppins, serif;
  font-weight: 700;
}
app-starfish-layout app-place-order .button-place-your-order:hover,
app-starfish-layout .default-button:hover {
  background-color: #f84f55 !important;
}
app-starfish-layout .schedule-consultation-title-container {
  padding-top: 1.5rem;
  margin-bottom: 0 !important;
}
app-starfish-layout .schedule-consultation-title-container h1 {
  font-weight: 600;
}
app-starfish-layout .transaction-history-summary .tabs a {
  color: #fa676b;
  border-bottom-color: #fa676b;
}
app-starfish-layout .is-primary-outlined {
  background-color: transparent !important;
  color: #fa676b !important;
  border-color: #fa676b !important;
}
app-starfish-layout .phone-order-user-banner {
  background-color: #fa676b;
  color: #fff;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
