// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.transaction-history-summary .table td {
  border: 0;
}
.transaction-history-summary .table .payment-detail-row {
  padding-left: 0;
  padding-right: 0;
}
@media only screen and (min-width: 768px) {
  .transaction-history-summary .table .payment-detail-row {
    min-width: 300px;
  }
}
@media only screen and (max-width: 768px) {
  .transaction-history-summary .table td:not(:first-child) {
    min-width: 120px;
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
